import AppLayout from '@/layout/AppLayout.vue';
import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: AppLayout,
            children: [
                {
                    path: '/',
                    name: 'dashboard',
                    component: () => import('@/components/dashboard/Dashboard.vue')
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: () => import('@/components/Profile.vue')
                },
                {
                    path: '/af-sales-managers',
                    name: 'af-sales-managers',
                    component: () => import('@/components/af-sales-managers/ViewAFSalesManagers.vue')
                },
                {
                    path: '/seller-companies',
                    name: 'seller-companies',
                    component: () => import('@/components/seller-companies/ViewSellerCompanies.vue')
                },
                {
                    path: "/seller-companies/:id",
                    name: "seller-companies-edit",
                    component: () => import('@/components/seller-companies/EditCompany.vue')
                },
                {
                    path: "/seller-companies/add",
                    name: "seller-companies-add",
                    component: () => import('@/components/seller-companies/AddCompany.vue')
                },
                {
                    path: "/seller-contacts",
                    name: "seller-contacs",
                    component: () => import('@/components/seller-contacts/ViewSellerContacts.vue')
                },
                {
                    path: '/buyer-companies',
                    name: 'buyer-companies',
                    component: () => import('@/components/buyer-companies/ViewBuyerCompanies.vue')
                },
                {
                    path: "/buyer-contacts",
                    name: "buyer-contacs",
                    component: () => import('@/components/buyer-contacts/ViewBuyerContacts.vue')
                },
                {
                    path: '/tiers',
                    name: 'ties',
                    component: () => import('@/components/tiers/ViewTiers.vue')
                },
                {
                    path: '/quotes',
                    name: 'quouotes',
                    component: () => import('@/components/quotes/ViewQuotes.vue')
                },
                {
                    path: "/logout",
                    name: "logout",
                    component: () => import('@/components/Logout.vue')
                }
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/components/Login.vue')
        },
        {
            path: '/password-set',
            name: 'password-set',
            component: () => import('@/components/PasswordSet.vue')
        }
    ]
});

export default router;
