<template>
    <li :class="{ 'layout-root-menuitem': root, 'active-menuitem': isActiveMenu }">
        <div v-if="root && item.visible !== false" class="layout-menuitem-root-text">{{ item.label }}</div>
        <a v-if="(!item.to || item.items) && item.visible !== false" :href="item.url" @click="itemClick($event, item, index)" :class="item.class" :target="item.target" tabindex="0">
            <i :class="item.icon" class="layout-menuitem-icon"></i>
            <span class="layout-menuitem-text">{{ item.label }}</span>
            <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
        </a>
        <router-link v-if="item.to && !item.items && item.visible !== false && typeof store.user !== 'undefined' && typeof store.user.scopes !== 'undefined' && store.user.scopes.some(r => item.scopes.includes(r))" @click="itemClick($event, item, index)" :class="[item.class, { 'bg-primary': checkActiveRoute(item) }]" tabindex="0" :to="item.to">
            <i class="layout-menuitem-icon icon-lg" :class="[checkActiveRoute(item) ? 'text-white' : '', item.icon]"></i>
            <span class="layout-menuitem-text" :class="{ 'text-white': checkActiveRoute(item) }">{{ item.label }}</span>
            <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
        </router-link>
        <Transition v-if="item.items && item.visible !== false" name="layout-submenu">
            <ul v-show="root ? true : isActiveMenu" class="layout-submenu">
                <app-menu-item v-for="(child, i) in item.items" :key="child" :index="i" :item="child" :parentItemKey="itemKey" :root="false"></app-menu-item>
            </ul>
        </Transition>
    </li>
</template>

<script>
import { useLayout } from '@/layout/composables/layout';
import { defineComponent, onBeforeMount, ref, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';
import store  from '../stores/store';

export default defineComponent({
    name: 'AppMenuItem',
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        index: {
            type: Number,
            default: 0
        },
        root: {
            type: Boolean,
            default: true
        },
        parentItemKey: {
            type: String,
            default: null
        }
    },
    setup(props) {
        const route = useRoute();

        const { layoutState, setActiveMenuItem, onMenuToggle } = useLayout();

        const isActiveMenu = ref(false);
        const itemKey = ref(null);
        //const store = useStore();

        onBeforeMount(() => {
            itemKey.value = props.parentItemKey ? props.parentItemKey + '-' + props.index : String(props.index);

            const activeItem = layoutState.activeMenuItem;

            isActiveMenu.value = activeItem === itemKey.value || activeItem ? activeItem.startsWith(itemKey.value + '-') : false;
        });

        watch(
            () => layoutState.activeMenuItem,
            (newVal) => {
                isActiveMenu.value = newVal === itemKey.value || newVal.startsWith(itemKey.value + '-');
            }
        );

        const itemClick = (event, item) => {
            if (item.disabled) {
                event.preventDefault();
                return;
            }

            if ((item.to || item.url) && (layoutState.staticMenuMobileActive || layoutState.overlayMenuActive)) {
                onMenuToggle();
            }

            if (item.command) {
                item.command({ originalEvent: event, item: item });
            }

            const foundItemKey = item.items ? (isActiveMenu.value ? props.parentItemKey : itemKey) : itemKey.value;

            setActiveMenuItem(foundItemKey);
        }

        const checkActiveRoute = (item) => {
            return route.path === item.to;
        }

        return {
            route,
            isActiveMenu,
            itemKey,
            store,
            itemClick,
            checkActiveRoute
        }
    }
});
</script>

<style lang="scss" scoped></style>
