<template>
    <Dialog v-model:visible="show" modal header="Quote Wizard" :style="{ width: '75rem' }" :closable="false">
        <template #header>
            <nav class=" px-6 pt-6 w-full" aria-label="Progress">
                <ol role="list" class="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border lg:border-gray-200">
                    <li v-for="(step, stepIdx) in store.quoteWizard.steps[store.quoteWizard.level]" :key="step.id" class="relative overflow-hidden lg:flex-1">
                        <div :class="[stepIdx === 0 ? 'rounded-t-md border-b-0' : '', stepIdx === store.quoteWizard.steps.length - 1 ? 'rounded-b-md border-t-0' : '', 'overflow-hidden border border-gray-200 lg:border-0']">
                            <div v-if="step.status === 'complete'" class="group hidden md:block">
                                <span class="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" aria-hidden="true" />
                                <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'flex items-center px-6 py-5 text-sm font-medium']">
                                    <span class="flex-shrink-0">
                                        <span class="flex h-10 w-10 items-center justify-center rounded-full bg-primary">
                                            <i class="pi pi-check text-white" aria-hidden="true" />
                                        </span>
                                    </span>
                                    <span class="ml-4 mt-0.5 flex min-w-0 flex-col">
                                        <span class="text-sm font-medium">{{ step.name }}</span>
                                    </span>
                                </span>
                            </div>
                            <div v-else-if="step.status === 'current'" aria-current="step">
                                <span class="absolute left-0 top-0 h-full w-1 bg-primary lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" aria-hidden="true" />
                                <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'flex items-center px-6 py-5 text-sm font-medium']">
                                    <span class="flex-shrink-0">
                                        <span class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-primary">
                                            <span class="text-primary">{{ step.id }}</span>
                                        </span>
                                    </span>
                                    <span class="ml-4 mt-0.5 flex min-w-0 flex-col">
                                        <span class="text-sm font-medium text-primary">{{ step.name }}</span>
                                    </span>
                                </span>
                            </div>
                            <div v-else class="group hidden md:block">
                                <span class="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" aria-hidden="true" />
                                <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'flex items-center px-6 py-5 text-sm font-medium']">
                                    <span class="flex-shrink-0">
                                        <span class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                                            <span class="text-gray-500">{{ step.id }}</span>
                                        </span>
                                    </span>
                                    <span class="ml-4 mt-0.5 flex min-w-0 flex-col">
                                        <span class="text-sm font-medium text-gray-500">{{ step.name }}</span>
                                    </span>
                                </span>
                            </div>
                            <template v-if="stepIdx !== 0">
                                <!-- Separator -->
                                <div class="absolute inset-0 left-0 top-0 hidden w-3 lg:block" aria-hidden="true">
                                    <svg class="h-full w-full text-gray-300" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                                        <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke" />
                                    </svg>
                                </div>
                            </template>
                        </div>
                    </li>
                </ol>
            </nav>
        </template>
        <div class="px-6 pt-2 mb-12">
            <div id="step0" v-if="store.quoteWizard.level == 'admin' && store.quoteWizard.activeStepIndex == 0">
                <Message severity="info" icon="pi pi-info-circle" class="mb-8">Search for and select the sales person on whose behalf this quote is being created</Message>
                <Message v-if="tiersWarning" severity="warn" icon="pi pi-exclamation-triangle" class="mb-8">{{ tiersWarningMessage }}</Message>
                <label for="sellerCompany" class="block text-primary dark:text-surface-0 font-medium mb-2">Sales person</label>
                <Select id="seller" v-model="seller" :options="sellersList" filter optionLabel="ContactName" placeholder="choose sales person" class="w-full" style="padding-top:0.15rem;padding-bottom:0.15rem;" @update:modelValue="setSalesPerson($event)">
                    <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex items-center">
                            {{ slotProps.value.ContactName }}
                        </div>
                        <span v-else>
                            {{ slotProps.placeholder }}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div class="flex flex-col">
                            <span>
                                <i class="icon icon-16 icon-seller-contact mr-2"></i> {{ slotProps.option.ContactName }}
                            </span>
                            <span class="flex text-sm text-gray-500">
                                <i class="icon icon-16 icon-seller-company mr-2"></i> {{ slotProps.option.company ? slotProps.option.company.FullLegalBusinessName : 'not assign to any seller company' }}
                            </span>
                        </div>
                    </template>
                </Select>
            </div>
            <div id="step1" v-if="(store.quoteWizard.level == 'admin' && store.quoteWizard.activeStepIndex == 1) || (store.quoteWizard.level == 'standard' && store.quoteWizard.activeStepIndex == 0)">
                <div>
                    <Message severity="info" icon="pi pi-info-circle" class="mb-8">Type in the name of the buyer company. If a similar company is found it will be listen below, and you can choose to either use it or continue creating brand new buyer company.</Message>
                    <label for="companyName" class="block text-primary dark:text-surface-0 font-medium mb-2">Company Name</label>
                    <IconField>
                        <InputText id="companyName" type="text" size="large" placeholder="company full legal business name" class="w-full" v-model="companyName" @update:model-value="searchCompanyByName()" autocomplete="off" />
                        <InputIcon class="pi pi-spin pi-spinner" v-if="nameSearch" />
                    </IconField>
                    <div class="my-4 px-6" v-if="buyerCompanyExist">
                        <ul role="list" class="divide-y divide-gray-100">
                            <li v-for="(company, cidx) in foundBuyerCompanies" :key="cidx" class="flex items-center justify-between gap-x-4 py-4">
                                <div class="min-w-0">
                                    <div class="flex items-start gap-x-3">
                                        <p class="text-sm font-semibold leading-6 text-gray-900">{{
                                            company.FullLegalBusinessName }}</p>
                                    </div>
                                </div>
                                <div class="flex flex-none items-center gap-x-4">
                                    <Button outlined size="small" @click="setBuyerCompany(company.Id, company.ContactId, company.FullLegalBusinessName)" label="Use this company" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="step2" v-if="(store.quoteWizard.level == 'admin' && store.quoteWizard.activeStepIndex == 2) || (store.quoteWizard.level == 'standard' && store.quoteWizard.activeStepIndex == 1)">
                <Message severity="info" icon="pi pi-info-circle" class="mb-8">Enter details of the new buyer contact. <span v-if="store.quoteWizard.buyerCompany.companyId !== null">If a similar contact is found it will be listen below, and you can choose to either use it or continue creating brand new buyer contact</span></Message>
                <div>
                    <label for="contactName" class="block text-primary dark:text-surface-0 font-medium mb-2">Buyer Contact Name</label>
                    <IconField>
                        <InputText id="contactName" type="text" size="large" placeholder="contact full name" class="w-full " v-model="contactName" @update:model-value="searchCompanyContactByName()" />
                        <InputIcon class="pi pi-spin pi-spinner" v-if="contactSearch" />
                    </IconField>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
                    <div class="col">
                        <label for="contactEmail" class="block text-primary dark:text-surface-0 font-medium mb-2">Email</label>
                        <InputText id="contactEmail" type="text" size="large" placeholder="buyer email" class="w-full" v-model="contactEmail" />
                    </div>
                    <div class="col">
                        <label for="contactPhone" class="block text-primary dark:text-surface-0 font-medium mb-2">Phone</label>
                        <InputNumber id="contactPhone" type="text" size="large" class="w-full" v-model="contactPhone" :useGrouping="false" />
                    </div>
                </div>
                <div class="my-4 px-6" v-if="buyerContactsExist">
                    <ul role="list" class="divide-y divide-gray-100">
                        <li v-for="(contact, cidx) in foundBuyerContacts" :key="cidx" class="flex items-center justify-between gap-x-4 py-4">
                            <div class="min-w-0">
                                <div class="flex items-start gap-x-3">
                                    <p class="text-sm font-semibold leading-6 text-gray-900">{{ contact.ContactName }}
                                    </p>
                                </div>
                            </div>
                            <div class="flex flex-none items-center gap-x-4">
                                <Button outlined size="small" @click="setBuyerContact(contact)" label="Use this contact" />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div id="step3" v-if="(store.quoteWizard.level == 'admin' && store.quoteWizard.activeStepIndex == 3) || (store.quoteWizard.level == 'standard' && store.quoteWizard.activeStepIndex == 2)">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-8 pt-4">
                    <div class="col">
                        <label for="quoteEquipment" class="block text-primary dark:text-surface-0 font-medium mb-2">Equipment Description</label>
                        <Textarea id="quoteEquipment" v-model="quoteEquipment" rows="5" cols="30" class="w-full" />
                    </div>
                    <div class="col">
                        <div class="mb-8">
                            <label for="quoteQuantity" class="block text-primary dark:text-surface-0 font-medium mb-2">Quantity</label>
                            <InputNumber id="quoteQuantity" type="text" size="large" class="w-full" v-model="quoteQuantity" :useGrouping="false" />
                        </div>
                        <div>
                            <label for="quoteTotalValue" class="block text-primary dark:text-surface-0 font-medium mb-2">Total Value</label>
                            <InputNumber id="quoteTotalValue" class="w-full" inputId="currency-us" mode="currency" currency="USD" locale="en-US" v-model="quoteValue" :invalid="noThreshold" @input="thresholdCheck($event)" />
                            <Message icon=" pi pi-times-circle" severity="error" v-if="noThreshold" class="mt-4">Tier for the given value not found</Message>
                        </div>
                    </div>
                </div>
            </div>
            <div id="step4" v-if="(store.quoteWizard.level == 'admin' && store.quoteWizard.activeStepIndex == 4) || (store.quoteWizard.level == 'standard' && store.quoteWizard.activeStepIndex == 3)">
                <div class="grid grid-cols-2 md:grid-cols-4 gap-8 pt-4">
                    <div :class="store.quoteWizard.level == 'admin' ? 'col-span-4 md:col-span-2' : 'col-span-4'">
                        <div v-if="store.quoteWizard.level == 'admin'" class="mb-8 flex block md:hidden flex-col w-full justify-center items-center p-2 bg-yellow-50 text-yellow-600 rounded-lg border border-yellow-200 text-sm">
                            <i class="pi pi-info-circle"></i>
                            <p>This quote will be created on behalf of</p>
                            <p class="py-1 text-lg font-semibold">{{ store.quoteWizard.salesPersonName }}</p>
                            <p>from</p>
                            <p class="py-1 text-lg font-semibold">{{ store.quoteWizard.salesPersonCompany }}</p>
                        </div>
                        <div>
                            <p class="flex mb-4 text-xl gap-4 items-center self-center">
                                <i class="icon icon-24 icon-buyer-company text-primary"></i>
                                {{ store.quoteWizard.buyerCompany.name }}
                                <Tag severity="success" value="Existing" v-if="store.quoteWizard.buyerCompany.contactId"></Tag>
                                <Tag severity="success" value="New" v-else></Tag>
                            </p>
                            <p class="flex mb-4 text-xl gap-4 items-center self-center">
                                <i class="icon icon-24 icon-buyer-contact text-primary"></i>
                                {{ store.quoteWizard.buyerContact.name }}
                                <Tag severity="success" value="Existing" v-if="store.quoteWizard.buyerContact.contactId"></Tag>
                                <Tag severity="success" value="New" v-else></Tag>
                            </p>
                            <p class="flex mb-4 gap-4 items-center self-center" :class="store.quoteWizard.buyerContact.email ? 'text-xl' : 'text-sm text-red-500'">
                                <i class="icon icon-24 icon-envelope text-primary"></i> {{ store.quoteWizard.buyerContact.email ? store.quoteWizard.buyerContact.email : 'no email - you will not be able to send the quote via email' }}
                            </p>
                            <p class="flex mb-4 gap-4 items-center self-center" :class="store.quoteWizard.buyerContact.phone ? 'text-xl' : 'text-sm text-red-500'">
                                <i class="icon icon-24 icon-phone text-primary"></i> {{ store.quoteWizard.buyerContact.phone ? store.quoteWizard.buyerContact.phone : 'no phone - you will not be able to send SMS with link to quote' }}
                            </p>
                        </div>
                    </div>
                    <div v-if="store.quoteWizard.level == 'admin'" class="flex hidden md:block flex-col col-span-2 justify-center items-center p-2 bg-yellow-50 text-yellow-600 rounded-lg border border-yellow-200 text-sm">
                        <i class="pi pi-info-circle"></i>
                        <p>This quote will be created on behalf of</p>
                        <p class="py-1 text-lg font-semibold">{{ store.quoteWizard.salesPersonName }}</p>
                        <p>from</p>
                        <p class="py-1 text-lg font-semibold">{{ store.quoteWizard.salesPersonCompany }}</p>
                    </div>
                    <div class="col-span-4">
                        <div class="grid grid-cols-2 border-t pt-8 items-top">
                            <p class="flex mb-4 gap-4 items-center self-center text-xl">
                                <i class="icon icon-24 icon-box-cart text-primary"></i> {{ store.quoteWizard.quote.equipment }}
                            </p>
                            <div>
                                <p class="flex mb-4 gap-4 items-center self-center text-xl">
                                    <i class="icon icon-24 icon-circle-hashtag text-primary"></i> {{ store.quoteWizard.quote.quantity }}
                                </p>
                                <p class="flex mb-4 gap-4 items-center self-center text-xl">
                                    <i class="icon icon-24 icon-invoice-dollar text-primary"></i> {{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(store.quoteWizard.quote.total_value) }}
                                </p>
                            </div>
                        </div>
                        <table class="min-w-full divide-y divide-gray-300 mt-4" v-if="selectedTier">
                            <thead class="bg-gray-200">
                                <tr>
                                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left font-semibold text-gray-900" :colspan="terms[selectedTier].length">
                                        Monthly Terms
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200" v-if="noThreshold">
                                <tr>
                                    <td colspan="3" class="text-center text-red-500">Tier for the given value was not found</td>
                                </tr>
                            </tbody>
                            <tbody class="divide-y divide-gray-200" v-if="selectedTier && !noThreshold">
                                <tr>
                                    <th class="whitespace-nowrap py-4 pl-4 pr-3 font-medium text-center text-gray-900" v-for="term in terms[selectedTier]" :key="term.Id">
                                        {{ term.TierMonths }}</th>
                                </tr>
                                <tr>
                                    <td class="whitespace-nowrap px-3 py-4 text-center text-gray-500" v-for="term in terms[selectedTier]" :key="term.Id">
                                        {{ new Intl.NumberFormat('en-US', {
                                            style: 'currency', currency: 'USD'
                                        }).format(term.TierRate
                                            *
                                            store.quoteWizard.quote.total_value) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button class="self-end" severity="secondary" @click="tiersWarning = false; $emit('close')">{{ store.quoteWizard.created ? 'Close' : 'Cancel' }}</Button>
            <div class="flex gap-4 flex-col md:flex-row justify-end items-center grow">
                <Button icon="pi pi-chevron-left" @click.prevent="prevStep()" v-if="store.quoteWizard.activeStepIndex > 0 && !store.quoteWizard.created" label="Previous" />
                <Button :disabled="(store.quoteWizard.level == 'admin' && store.quoteWizard.sellerCompanyId == null && store.quoteWizard.salesPersonId == null) || tiersPreventProgress" @click.prevent="nextStep()" v-if="(store.quoteWizard.level == 'admin' && store.quoteWizard.activeStepIndex < 4) || (store.quoteWizard.level == 'standard' && store.quoteWizard.activeStepIndex < 3)">
                    <span v-if="store.quoteWizard.level == 'admin' && store.quoteWizard.activeStepIndex == 0 && store.quoteWizard.salesPersonId !== null">Continue with sellected seller</span>

                    <span v-else-if="store.quoteWizard.level == 'admin' && store.quoteWizard.activeStepIndex == 1 && store.quoteWizard.buyerCompany.companyId == null">Continue with new company</span>
                    <span v-else-if="store.quoteWizard.level == 'standard' && store.quoteWizard.activeStepIndex == 0 && store.quoteWizard.buyerCompany.companyId == null">Continue with new company</span>
                    <span v-else-if="store.quoteWizard.level == 'admin' && store.quoteWizard.activeStepIndex == 1 && store.quoteWizard.buyerCompany.companyId !== null">Continue with existing company</span>
                    <span v-else-if="store.quoteWizard.level == 'standard' && store.quoteWizard.activeStepIndex == 0 && store.quoteWizard.buyerCompany.companyId !== null">Continue with existing company</span>

                    <span v-else-if="store.quoteWizard.level == 'admin' && store.quoteWizard.activeStepIndex == 2 && store.quoteWizard.buyerContact.contactId == null">Continue with new contact</span>
                    <span v-else-if="store.quoteWizard.level == 'standard' && store.quoteWizard.activeStepIndex == 1 && store.quoteWizard.buyerContact.contactId == null">Continue with new contact</span>
                    <span v-else-if="store.quoteWizard.level == 'admin' && store.quoteWizard.activeStepIndex == 2 && store.quoteWizard.buyerContact.contactId !== null">Continue with existing contact</span>
                    <span v-else-if="store.quoteWizard.level == 'standard' && store.quoteWizard.activeStepIndex == 1 && store.quoteWizard.buyerContact.contactId !== null">Continue with existing contact</span>

                    <span v-else>Continue</span>
                    <i class="pi pi-chevron-right"></i>
                </Button>
                <div class="flex items-center gap-2" v-if="store.quoteWizard.created">
                    <span>Include 2nd page</span>
                    <ToggleSwitch v-model="secondPage">
                        <template #handle="{ checked }">
                            <i :class="['!text-xs pi', { 'pi-check': checked, 'pi-times': !checked }]" />
                        </template>
                    </ToggleSwitch>
                </div>
                <Button @click="toggle" label="Send Quote" v-if="store.quoteWizard.created" />
                <Menu ref="menu" id="overlay_menu" :model="sendOptions" :popup="true" />
                <Button v-if="store.quoteWizard.created" type="button" @click.prevent="submitDownload()" icon="pi pi-download" label="Download" />
                <Button class="block md:hidden" v-if="(store.quoteWizard.level == 'admin' && store.quoteWizard.activeStepIndex == 4) || (store.quoteWizard.level == 'standard' && store.quoteWizard.activeStepIndex == 3)" :disabled="store.quoteWizard.created" @click.prevent="submitQuote()" :label="store.quoteWizard.created ? 'Quote Created' : 'Create Quote'" />
            </div>
        </template>
    </Dialog>
    <Dialog v-model:visible="confirmation" modal header="" :style="{ width: '25rem' }" :closable="false">
        <div class="flex mb-16">
            <p class="text-center text-gray-500" v-if="confirmationMode == 'email'">
                Are you sure you want to email this quote to<br><span class="font-semibold">{{ store.quoteWizard.buyerContact.name }}</span><br> at<br> <span class="font-semibold">{{ store.quoteWizard.buyerContact.email }}</span> ?
            </p>
            <p class="text-center text-gray-500" v-if="confirmationMode == 'sms'">
                Are you sure you want to email this quote to<br><span class="font-semibold">{{ store.quoteWizard.buyerContact.name }}</span><br> at<br> <span class="font-semibold">{{ store.quoteWizard.buyerContact.phone }}</span> ?
            </p>
            <p class="text-center text-gray-500" v-if="confirmationMode == 'both'">
                Are you sure you want to send this quote to<br><span class="font-semibold">{{ store.quoteWizard.buyerContact.name }}</span><br><br> via email to<br> <span class="font-semibold">{{ store.quoteWizard.buyerContact.email }}</span><br><br>and via SMS to<br> <span class="font-semibold">{{ store.quoteWizard.buyerContact.phone }}</span> ?
            </p>
        </div>
        <div class="flex justify-end gap-2">
            <Button type="button" label="Cancel" severity="secondary" @click="confirmation = false"></Button>
            <Button type="button" label="Confirm" @click="sendQuote(confirmationMode)"></Button>
        </div>
    </Dialog>
</template>

<style>
.p-inputnumber-input {
    padding-top: 0.73rem !important;
    padding-bottom: 0.73rem !important;
}
</style>

<script>
import axios from "axios";
import { debounce } from 'lodash-es';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { computed, defineComponent, ref, toRefs, watch, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router';
//import { useStore } from '../../stores/store';
import store from '../../stores/store';

export default defineComponent({
    name: 'QuoteWizard',
    title: 'Quotes Wizard',
    emits: ['close', 'refresh'],
    props: ['show'],
    components: {

    },
    setup(props, { emit }) {

        const instance = getCurrentInstance();
        try {
            const router = useRouter();
            const route = useRoute();
            //const store = useStore();
            const confirm = useConfirm();
            const toast = useToast();
            const { show } = toRefs(props);
            const app_web = import.meta.env.VITE_APP_WEB;
            const nameSearch = ref(false);
            const contactSearch = ref(false);
            const buyerCompanyExist = ref(false);
            const foundBuyerCompanies = ref(null);
            const foundBuyerContacts = ref(null);
            const buyerContactsExist = ref(false);
            const noThreshold = ref(false);
            const tiers = ref([]);
            const selectedTier = ref(null);
            const thresholds = ref([]);
            const terms = ref({});
            const secondPage = ref(false);
            const sendConfirmation = ref(false);
            const sendTarget = ref('email');
            const sellersList = ref([]);
            const tiersWarning = ref(false);
            const tiersPreventProgress = ref(false);
            const tiersWarningMessage = ref('');
            const menu = ref();
            const confirmation = ref(false);
            const confirmationMode = ref(null);
            const sendOptions = ref([
                {
                    items: [
                        {
                            label: 'Email',
                            command: () => {
                                showConfirm('email');
                            }
                        },
                        {
                            label: 'SMS',
                            command: () => {
                                showConfirm('sms');
                            }
                        },
                        {
                            label: 'Email & SMS',
                            command: () => {
                                showConfirm('both');
                            }
                        }
                    ]
                }
            ]);

            const showConfirm = (mode) => {
                confirmationMode.value = mode;
                confirmation.value = true;
            }

            const toggle = (event) => {
                menu.value.toggle(event);
            };

            const seller = computed({
                get() {
                    return store.quoteWizard.salesPerson;
                },
                set(value) {
                    store.quoteWizard.salesPersonName = value;
                }
            });

            const companyName = computed({
                get() {
                    return store.quoteWizard.buyerCompany.name;
                },
                set(value) {
                    store.quoteWizard.buyerCompany.name = value;

                    //reset the id's everytime user types something
                    store.quoteWizard.buyerCompany.companyId = null;
                    store.quoteWizard.buyerCompany.contactId = null;
                }
            });

            const contactName = computed({
                get() {
                    return store.quoteWizard.buyerContact.name;
                },
                set(value) {
                    store.quoteWizard.buyerContact.name = value;

                    //reset the id's everytime user types something
                    store.quoteWizard.buyerContact.contactId = null;
                }
            });

            const contactEmail = computed({
                get() {
                    return store.quoteWizard.buyerContact.email;
                },
                set(value) {
                    store.quoteWizard.buyerContact.email = value;

                    //reset the id's everytime user types something
                    store.quoteWizard.buyerContact.contactId = null;
                }
            });

            const contactPhone = computed({
                get() {
                    return store.quoteWizard.buyerContact.phone;
                },
                set(value) {
                    store.quoteWizard.buyerContact.phone = value;

                    //reset the id's everytime user types something
                    store.quoteWizard.buyerContact.contactId = null;
                }
            });

            const quoteEquipment = computed({
                get() {
                    return store.quoteWizard.quote.equipment;
                },
                set(value) {
                    store.quoteWizard.quote.equipment = value;
                }
            });

            const quoteQuantity = computed({
                get() {
                    return store.quoteWizard.quote.quantity;
                },
                set(value) {
                    store.quoteWizard.quote.quantity = value;
                }
            });

            watch(() => props.show, (newValue) => {
                if (newValue) {
                    if (store.quoteWizard.level == 'admin') {
                        fetchSellers();
                    }
                }
            });

            const quoteValue = computed({
                get() {
                    return store.quoteWizard.quote.total_value;
                    //return rawTotalValueInput.value;
                },
                set(value) {
                    // Remove any invalid characters and limit to one period
                    //let sanitizedValue = value.replace(/[^0-9.]/g, '');

                    // Ensure only one period
                    //const parts = sanitizedValue.split('.');
                    //if (parts.length > 2) {
                    //    sanitizedValue = parts[0] + '.' + parts[1];
                    //}

                    // Allow only two decimal places
                    //if (parts[1] && parts[1].length > 2) {
                    //    sanitizedValue = parts[0] + '.' + parts[1].substring(0, 2);
                    //}

                    //rawTotalValueInput.value = sanitizedValue; // Update the raw input

                    // Convert to float and store in Pinia
                    //const floatValue = sanitizedValue ? parseFloat(sanitizedValue) : 0;
                    //store.setWizardTotalValue(floatValue);
                    store.quoteWizard.quote.total_value = value;

                    // Call the debounced formatting function
                    //debouncedFormat(sanitizedValue);
                }
            });

            const setBuyerCompany = (id, cid, name) => {
                store.quoteWizard.buyerCompany.companyId = id;
                store.quoteWizard.buyerCompany.contactId = cid;
                store.quoteWizard.buyerCompany.name = name;
                buyerCompanyExist.value = false;
                foundBuyerCompanies.value = null;
            }

            const setBuyerContact = (contact) => {
                store.quoteWizard.buyerContact.contactId = contact.Id;
                store.quoteWizard.buyerContact.name = contact.ContactName;
                if (typeof contact.emails !== 'undefined' && contact.emails.length > 0) {
                    store.quoteWizard.buyerContact.email = contact.emails[0].EmailAddress;
                }
                if (typeof contact.phones !== 'undefined' && contact.phones.length > 0) {
                    store.quoteWizard.buyerContact.phone = parseInt(contact.phones[0].PhoneNumber);
                }
                buyerContactsExist.value = false;
                foundBuyerContacts.value = null;
            }

            const setSalesPerson = (contact) => {
                let sellerData = {
                    sellerContactId: contact.company !== null ? contact.company.ContactId : null,
                    sellerCompanyId: contact.company !== null ? contact.company.Id : null,
                    sellerCompanyName: contact.company !== null ? contact.company.FullLegalBusinessName : null,
                    salesPersonId: contact.seller.Id,
                    salesPersonName: contact.ContactName,
                    salesPerson: contact
                };
                store.quoteWizard.sellerContactId = sellerData.sellerContactId;
                store.quoteWizard.sellerCompanyId = sellerData.sellerCompanyId;
                store.quoteWizard.salesPersonCompany = sellerData.sellerCompanyName;
                store.quoteWizard.salesPersonId = sellerData.salesPersonId;
                store.quoteWizard.salesPersonName = sellerData.salesPersonName;
                store.quoteWizard.salesPerson = sellerData.salesPerson;

                //grab thresholds for selected company
                if (contact.company !== null) {
                    tiersWarning.value = tiersPreventProgress.value = false;
                    tiersWarningMessage.value = '';
                    fetchTiers(contact.company.Id);
                } else {
                    tiersWarning.value = true;
                    tiersPreventProgress.value = true;
                    tiersWarningMessage.value = 'This seller is not assigned to a seller company. The system is unable to fetch financing tiers, and create the quote';
                }
            }

            const searchCompanyByName = debounce((val) => {
                if (store.quoteWizard.buyerCompany.name === null || store.quoteWizard.buyerCompany.name.trim() === '') {
                    return false;
                }
                nameSearch.value = true;
                let sellerId = store.quoteWizard.sellerContactId ? store.quoteWizard.sellerContactId : store.user.contact.ParentId;
                axios({
                    method: "GET",
                    url: import.meta.env.VITE_APP_API + "/companies/buyer?sellerId=" + sellerId + "&name=" + store.quoteWizard.buyerCompany.name,
                    headers: { Authorization: 'Bearer ' + store.token },
                }).then((response) => {
                    nameSearch.value = false;
                    if (Object.keys(response.data).length > 0) {
                        foundBuyerCompanies.value = response.data;
                        buyerCompanyExist.value = true;
                    } else {
                        nameSearch.value = false;
                        foundBuyerCompanies.value = null;
                        buyerCompanyExist.value = false;
                    }
                })
            }, 500);

            const searchCompanyContactByName = debounce((val) => {
                //search contact only if buyer company id is set
                if (store.quoteWizard.buyerCompany.contactId === null || store.quoteWizard.buyerContact.name === null || store.quoteWizard.buyerContact.name.trim() === '') {
                    return false;
                }
                contactSearch.value = true;
                axios({
                    method: "GET",
                    url: import.meta.env.VITE_APP_API + "/contacts/buyer?companyContactId=" + store.quoteWizard.buyerCompany.contactId + "&name=" + store.quoteWizard.buyerContact.name,
                    headers: { Authorization: 'Bearer ' + store.token },
                }).then((response) => {
                    contactSearch.value = false;
                    if (Object.keys(response.data).length > 0) {
                        foundBuyerContacts.value = response.data;
                        buyerContactsExist.value = true;
                    } else {
                        contactSearch.value = false;
                        foundBuyerContacts.value = null;
                        buyerContactsExist.value = false;
                    }
                })
            }, 500);


            const thresholdCheck = debounce((event) => {
                let val = event.value;
                noThreshold.value = false;
                selectedTier.value = null;
                store.quoteWizard.quote.total_value = val;
                for (const thr of thresholds.value) {
                    // now check
                    if (thr.TierThresholdStart == null && parseFloat(val) <= thr.TierThresholdEnd) {
                        selectedTier.value = thr.TierId;
                        break;
                    }
                    else if (parseFloat(val) >= thr.TierThresholdStart && parseFloat(val) <= thr.TierThresholdEnd) {
                        selectedTier.value = thr.TierId;
                        break;
                    }
                    else if (parseFloat(val) >= thr.TierThresholdStart && thr.TierThresholdEnd == null) {
                        selectedTier.value = thr.TierId;
                        break;
                    }
                };

                noThreshold.value = (selectedTier.value === null) ? true : false;
            }, 500);

            const nextStep = () => {
                let currentIdx = store.quoteWizard.activeStepIndex;
                let nextIdx = store.quoteWizard.activeStepIndex + 1;

                store.quoteWizard.steps[store.quoteWizard.level][currentIdx].status = 'complete';

                store.quoteWizard.steps[store.quoteWizard.level][nextIdx].status = 'current';
                store.quoteWizard.activeStepIndex = nextIdx;

                //reset search results
                buyerCompanyExist.value = false;
                foundBuyerCompanies.value = null;
                buyerContactsExist.value = false;
                foundBuyerContacts.value = null;
            }

            const prevStep = () => {
                let currentIdx = store.quoteWizard.activeStepIndex;
                let prevIdx = store.quoteWizard.activeStepIndex - 1;

                store.quoteWizard.steps[store.quoteWizard.level][currentIdx].status = 'complete';

                store.quoteWizard.steps[store.quoteWizard.level][prevIdx].status = 'current';
                store.quoteWizard.activeStepIndex = prevIdx;
            }

            const fetchTiers = (sellerCompanyId = null) => {
                let companyId = (typeof store.user.company !== 'undefined' && store.quoteWizard.level == 'standard') ? store.user.company.Id : sellerCompanyId;
                if (companyId == null) {
                    return false;
                }
                //reset tiers
                noThreshold.value = false;
                tiers.value = [];
                selectedTier.value = null;
                thresholds.value = [];
                terms.value = {};
                axios({
                    method: "GET",
                    url: import.meta.env.VITE_APP_API + "/tiers/company/" + companyId,
                    headers: { Authorization: 'Bearer ' + store.token },
                }).then((response) => {
                    if (response.data.length > 0) {
                        tiers.value = response.data;
                        // terms
                        response.data.forEach(function (item) {
                            terms.value[item.id] = item.terms;
                        });
                        // thresholds
                        response.data.forEach(function (item) {
                            if (item.thresholds !== null) {
                                thresholds.value.push(item.thresholds);
                            }
                        });
                        if (thresholds.value.length < tiers.value.length) {
                            tiersWarning.value = true;
                            tiersPreventProgress.value = false;
                            tiersWarningMessage.value = "It appears not every tier in the parent company has it's value thresholds set - this may have an adverse effect when creating quotes";
                        }
                    } else {
                        tiersWarning.value = true;
                        tiersPreventProgress.value = true;
                        tiersWarningMessage.value = 'Parent seller company does not have any financing tiers assigned. The system will not be able to create the quote';
                    }
                })
                    .catch(() => {
                        //fetching.value = false;
                    })
            }
            const defaultCompany = store.user.company ? store.user.company.Id : null;
            fetchTiers(defaultCompany); // assuming user company by default


            watch(() => show.value, (newValue, oldValue) => {
                // Trigger getQuotes() whenever the user changes
                if (newValue) {
                    if(store.user.company) {
                        fetchTiers(store.user.company.Id);
                    }

                }
            });

            const fetchSellers = () => {
                axios({
                    method: "GET",
                    url: import.meta.env.VITE_APP_API + "/contacts/seller",
                    headers: { Authorization: 'Bearer ' + store.token },
                }).then((response) => {
                    sellersList.value = response.data;
                })
            }

            const sendQuote = (mode) => {
                let msg = '';
                if (mode === 'email') {
                    msg = 'Send Quote via Email';
                }
                if (mode === 'sms') {
                    msg = 'Send Quote via SMS';
                }
                if (mode === 'both') {
                    msg = 'Send Quote via Email & SMS';
                }
                toast.add({ severity: 'info', summary: msg, detail: 'this functionality is temporarily disabled', life: 3000 });
                confirmation.value = false;
            }

            return {
                store,
                app_web,
                nameSearch,
                contactSearch,
                companyName,
                contactName,
                contactEmail,
                contactPhone,
                quoteEquipment,
                quoteQuantity,
                quoteValue,
                buyerCompanyExist,
                foundBuyerCompanies,
                buyerContactsExist,
                foundBuyerContacts,
                searchCompanyByName,
                searchCompanyContactByName,
                noThreshold,
                tiers,
                selectedTier,
                thresholds,
                terms,
                secondPage,
                sendConfirmation,
                sendTarget,
                sellersList,
                setBuyerCompany,
                setBuyerContact,
                setSalesPerson,
                thresholdCheck,
                nextStep,
                prevStep,
                tiersWarningMessage,
                tiersWarning,
                tiersPreventProgress,
                seller,
                toast,
                toggle,
                sendOptions,
                confirmation,
                confirmationMode,
                showConfirm,
                menu,
                sendQuote,

                submitQuote() {
                    let queryParams = '';
                    // if user is being impersonated add the user id to the request query
                    if (Object.keys(store.impersonator).length > 0) {
                        queryParams = '?impersonate=' + store.user.Id;
                    }

                    let formData = new FormData();
                    if (store.quoteWizard.buyerCompany.companyId !== null) {
                        formData.set("BuyerCompanyId", store.quoteWizard.buyerCompany.companyId);
                    } else {
                        formData.set("BuyerCompanyName", store.quoteWizard.buyerCompany.name);
                    }
                    if (store.quoteWizard.buyerContact.contactId !== null) {
                        formData.set("BuyerContactId", store.quoteWizard.buyerContact.contactId);
                    } else {
                        formData.set("BuyerContactName", store.quoteWizard.buyerContact.name);
                        if (store.quoteWizard.buyerContact.email !== null && store.quoteWizard.buyerContact.email.trim() !== '') {
                            formData.set("BuyerContactEmail", store.quoteWizard.buyerContact.email);
                        }
                        if (store.quoteWizard.buyerContact.phone !== null) {
                            formData.set("BuyerContactPhone", store.quoteWizard.buyerContact.phone);
                        }
                    }
                    if (store.quoteWizard.sellerCompanyId !== null && store.quoteWizard.salesPersonId !== null) {
                        formData.set("SellerCompanyId", store.quoteWizard.sellerCompanyId);
                        formData.set("SalesPersonId", store.quoteWizard.salesPersonId);
                    }
                    formData.set("Equipment", store.quoteWizard.quote.equipment);
                    formData.set("Quantity", store.quoteWizard.quote.quantity);
                    formData.set("TotalValue", store.quoteWizard.quote.total_value);
                    formData.set("TierTerms", JSON.stringify(terms.value[selectedTier.value]));

                    axios({
                        method: "POST",
                        url: import.meta.env.VITE_APP_API + '/quote-wizard' + queryParams,
                        data: formData,
                        headers: { "Content-Type": "multipart/form-data", "Authorization": 'Bearer ' + store.token },
                    }).then(
                        response => {
                            store.quoteWizard.created = true;
                            store.quoteWizard.quote.id = response.data.Id;
                            toast.add({ severity: 'success', summary: 'Success', detail: 'Quote created', life: 5000 });
                            emit('refresh');
                        },
                        error => {
                            toast.add({ severity: 'error', summary: 'Error', detail: 'unable to create new quote', life: 5000 });
                        }
                    );

                },

                submitDownload() {
                    let uri = import.meta.env.VITE_APP_WEB + '/quote/download/' + store.quoteWizard.quote.id + '?return=stream&secondPage=' + secondPage.value;
                    window.open(uri, '_blank').focus();
                },

            }
        } catch (error) {
            instance.appContext.config.globalProperties.$logError(error.message, 'unknown', null, null, error);
        }
    }

});
</script>

<style scoped></style>