<template>
    <div class="layout-topbar drop-shadow-sm">
        <div class="layout-topbar-logo-container">
            <button class="layout-menu-button layout-topbar-action" @click="onMenuToggle">
                <i class="pi pi-bars"></i>
            </button>
            <router-link to="/" class="layout-topbar-logo">
                <img class="h-8 hidden md:block" src="../assets/images/AmpliFi-blue-black.png" alt="AmpliFi" v-if="!isDarkTheme" />
                <img class="h-8 block md:hidden" src="../assets/images/AmpliFi-small-blue-black.png" alt="AmpliFi" v-if="!isDarkTheme" />
                <img class="h-8 hidden md:block" src="../assets/images/AmpliFi-white-white.png" alt="AmpliFi" v-if="isDarkTheme" />
                <img class="h-8 block md:hidden" src="../assets/images/AmpliFi-small-white-white.png" alt="AmpliFi" v-if="isDarkTheme" />
            </router-link>
        </div>

        <div class="layout-topbar-actions flex items-center">
            <div class="hidden md:block">
                <Button icon="pi pi-plus" label="New Quote" @click.prevent="store.quoteWizard.show = true"/>
            </div>
            <div class="block md:hidden">
                <Button icon="pi pi-plus" @click.prevent="store.quoteWizard.show = true"/>
            </div>
            <div class="layout-config-menu">
                <button type="button" class="layout-topbar-action" @click="toggleDarkMode">
                    <i :class="['pi', { 'pi-moon': isDarkTheme, 'pi-sun': !isDarkTheme }]"></i>
                </button>
                <div class="relative" v-if="showThemeSelector">
                    <button v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }" type="button" class="layout-topbar-action layout-topbar-action-highlight">
                        <i class="pi pi-palette"></i>
                    </button>
                    <AppConfigurator />
                </div>
            </div>

            <button class="layout-topbar-menu-button layout-topbar-action" v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }">
                <i class="pi pi-ellipsis-v"></i>
            </button>

            <div class="layout-topbar-menu hidden lg:block">
                <div class="layout-topbar-menu-content">
                    <button type="button" class="layout-topbar-action" v-if="showCalendar">
                        <i class="pi pi-calendar"></i>
                        <span>Calendar</span>
                    </button>
                    <button type="button" class="layout-topbar-action" v-if="showMessages">
                        <i class="pi pi-inbox"></i>
                        <span>Messages</span>
                    </button>
                    <Button text type="button" :label="typeof store.user !== 'undefined' && typeof store.user.contact !== 'undefined' ? store.user.contact.ContactName : ''" icon="pi pi-user" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" />
                    <Popover ref="op">
                        <div class="flex flex-col">
                            <router-link to="/profile" class="px-6 py-2 hover:bg-gray-100"><i class="pi pi-id-card"></i> Profile</router-link>
                            <router-link to="/logout" class="px-6 py-2 hover:bg-gray-100"><i class="pi pi-sign-out"></i> Logout</router-link>
                        </div>
                    </Popover>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useLayout } from '@/layout/composables/layout';
//import { storeToRefs } from 'pinia';
import { ref } from "vue";
import store from '../stores/store';
import AppConfigurator from './AppConfigurator.vue';

//const store = useStore();
//const { user, showQuoteWizzard } = storeToRefs(store);

const { onMenuToggle, toggleDarkMode, isDarkTheme } = useLayout();
const showThemeSelector = false;
const showCalendar = false;
const showMessages = false;
const op = ref();
const toggle = (event) => {
    op.value.toggle(event);
};
</script>
<style>
.p-popover-content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 5px;
    padding-bottom: 5px;
}
</style>
